<template >
  <div>
    <div class="row">
      <div class="col-md-12">
        <v-card v-if="selected == null && created == null" style="margin-bottom: 30px">
          <v-card-title>
            Cities
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
             <b-button type="button" @click="clicked2()" variant="primary">New City</b-button>
          </v-card-title>
          <v-data-table :headers="headers" :items="cities" :search="search" style="cursor: pointer;"></v-data-table>
        </v-card>
      </div>

      <div v-if="created">
        <b-card class="mt-3" header="Add new city">
          <b-row>
            <b-col>
              <b-form-group id="input-group-2" label="City:" label-for="input-2">
                <b-form-input id="input-2" v-model="city"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="input-group-2" label="State:" label-for="input-2">
                <b-form-input id="input-2" v-model="state"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            type="button"
            @click="created = null"
            variant="danger"
            style="margin-right: 20px;"
          >Return</b-button>
          <b-button type="button" @click="newCity()" variant="primary">Save</b-button>
        </b-card>

      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "cities",
  methods: {
    clicked(value) {
      this.selected = value;
      console.log(this.selected);
    },

    clicked2(){
      this.created = 1;
      console.log(this.created)
      console.log('oi')
    },
    newCity(){
      console.log(this.city)
      console.log(this.state)

      fetch(
        "https://cleancity.krueltech.com/dev/api/api.php?action=newCity&city=" + this.city + "&state="+ this.state
      )
        .then(window.location.reload())
        .catch(console.log);
    },

    approve(id) {
      fetch(
        "https://cleancity.krueltech.com/dev/api/api.php?action=approveNewHome&id=" + id
      )
        .then(window.location.reload())
        .catch(console.log);
    },

  },
  data() {
    return {
      cities: [],
      worker: [],
      workerBackup: [],
      orderAtual: null,
      city: '',
      state: '',
      fields: ["name", "city", "address", "type"],
      form: {
        email: "",
        name: "",
        registrationDate: "",
        food: null,
        checked: []
      },
      search: "",
      search2: "",
      selected: null,
      created: null,
      headers: [
        { text: "City", value: "city" },
        { text: "State", value: "state" }
      ]
    };
  },
  mounted() {
    var auth = localStorage.getItem("name");
    if (auth != null && auth != "undefined") {
      console.log("existe");
    } else {
      console.log("nao existe");
      this.$router.push({ name: "login" });
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Tables", route: "simple-tables" },
      { title: "Data Tables" }
    ]);

    fetch("https://cleancity.krueltech.com/dev/api/api.php?action=readCities")
      .then(res => res.json())
      .then(data => {
        this.cities = data.cities;
        console.log(this.cities)
      })
      .catch(console.log);
  }
};
</script>
